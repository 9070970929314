export const awaitWrap = (promise) => {
    return promise
        .then(data => [null, data])
        .catch(err => [err, null])
}

export const handlePromise = (list) => {
    return list.map(promise => {
        return promise.then(res => {
            return res
        }).catch(e => {
            return e
        })
    })
}

export const translateDataToTree = (data) => {
    const parents = data.filter(value => value.parentId === 0 || value.parentId === undefined || value.parentId === null) // 没有父节点的数据
    const children = data.filter(value => value.parentId !== 0) // 有父节点的数据
    // 定义转换方法的具体实现
    const translator = (parents, children) => {
        parents.forEach((parent) => {
                children.forEach((current, index) => {
                        if (current.parentId === parent.deptId) {
                            const temp = JSON.parse(JSON.stringify(children))
                            temp.splice(index, 1)
                            translator([current], temp)
                            typeof parent.children !== 'undefined' ? parent.children.push(current) : parent.children = [current]
                        }
                    }
                )
            }
        )
    }
    translator(parents, children)
    return parents
}

// 深度拷贝函数
export const deepCopy = (data) => {
    let t = this.type(data), o, i, ni;
    if (t === 'array') {
        o = [];
    } else if (t === 'object') {
        o = {};
    } else {
        return data;
    }
    if (t === 'array') {
        for (i = 0, ni = data.length; i < ni; i++) {
            o.push(this.deepCopy(data[i]));
        }
        return o;
    } else if (t === 'object') {
        for (i in data) {
            o[i] = this.deepCopy(data[i]);
        }
        return o;
    }
}