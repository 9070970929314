// consumer_department_deleteDepartmentgetDepartmentList 删除部门
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-dept-controller/deleteDepartmentUsingPOST
const consumer_department_deleteDepartment = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/department/deleteDepartment',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_department_deleteDepartment;